import { Redis } from '@upstash/redis'
import 'process'
let client = null;
const startConnection = async () => {

  client = new Redis({
    url: process.env.REACT_APP_REDIS_URL,
    token: process.env.REACT_APP_REDIS_TOKEN,
  })

  return client;
 // client.on("error", (err) => console.log("Redis Client Error", err));

 // await client.connect();
};
const closeConnection = async () => {
  if (client === null) return;
  //await client.disconnect();
};

const getKey = async (key) => {
  const value = await client.get(key);
  return value;
};

const setKey = async (key, value) => {
  await client.set(key, value);
};

const getJSON = async (key) => {
  const value = await client.get(key);
  return JSON.parse(value);
};

const setJSON = async (key, value) => {
  await client.set(key, JSON.stringify(value));
};

const createHolidayIndex = async (key, value) => {
  try {
    await client.ft.create("idx:holidays", {
      "$.summary": {
        type: SchemaFieldTypes.TEXT,
        SORTABLE: true,
        AS: "holidayName",
      },
      "$.start.date": {
        type: SchemaFieldTypes.TEXT,
        SORTABLE: true,
        AS: "holidayDate",
      },
      "$.description": {
        type: SchemaFieldTypes.TEXT,
        SORTABLE: true,
        AS: "isHoliday",
      },
    });
  } catch (err) {
    if (err.message !== "Index already exists") {
      throw err;
    } else {
      console.log("Index already exists");
    }
  }
};

export { startConnection, closeConnection, getKey, setKey, getJSON, setJSON, createHolidayIndex };
