import React, { Component } from "react";
import Calendar from "./Component/Calendar/Calendar";
import "./css/App.css";
import { Helmet, HelmetProvider } from "react-helmet-async";

class App extends Component {
  constructor(props) {
    super(props);
    // Set initial state here
    this.state = {};
  }

  render() {
    return (
      <HelmetProvider>
        <Helmet>
          <title>Calendar App</title>
          <meta
            name='viewport'
            content='width=device-width, initial-scale=1, minimum-scale=0.1, maximum-scale=1'
          ></meta>
        </Helmet>
        <div className='App bg-customColor-primary' id='root'>
          <Calendar />
        </div>
      </HelmetProvider>
    );
  }
}

export default App;
