//this is loading component

import React from "react";
import "./css/loading.css";
import { useTranslation } from "react-i18next";
const Loading = ({ loading }) => {
  const { t } = useTranslation();

  return loading ? (
    <div className='loading-container'>
      <div className='loading'>
        <div className='loading__letter'>L</div>
        <div className='loading__letter'>O</div>
        <div className='loading__letter'>A</div>
        <div className='loading__letter'>D</div>
        <div className='loading__letter'>I</div>
        <div className='loading__letter'>N</div>
        <div className='loading__letter'>G</div>
      </div>
      <div className='loading__text'>{t("loading")}</div>
    </div>
  ) : (
    <></>
  );
};

export default Loading;
