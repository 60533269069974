/*****this is drop down component */
import React, { useEffect, useMemo, useState, useRef } from "react";
import "./css/DropDown.css";

const DropDown = ({ dataArr, visible, setVisible, setCurrentData, className, ...props }) => {
  const _dataArr = useMemo(() => {
    return dataArr;
  }, [dataArr]);
  const handleClick = (data) => {
    setCurrentData(data);
    setVisible(!visible);
  };
  let searchKey = "";
  const scrollRef = useRef(null);
  useEffect(() => {
    function handleKeyDown(event) {
      // Perform actions based on the keydown event'
      if (!visible) {
        return;
      }
      searchKey = searchKey + event.key;
      let firstFindIndex = 0;
      dataArr.find((data, index) => {
        if (data.toString().toLowerCase() !== undefined) {
          if (data?.toString().toLowerCase()?.startsWith(searchKey.toString().toLowerCase())) {
            if (firstFindIndex === 0) firstFindIndex = index;
          }
        }
      });
      scrollRef.current.scrollTo(0, firstFindIndex * 30);
      setTimeout(() => {
        searchKey = "";
      }, 1000);
    }

    document.addEventListener("keydown", handleKeyDown);

    // Clean up the event listener when the component is unmounted
    return () => {
      searchKey = "";
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [visible]);

  return (
    <div
      ref={scrollRef}
      className={
        "max-h-[400px] w-auto max-w-[300px] overflow-y-scroll absolute z-10 w-40 -mt-5 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none " +
        className
      }
      role='menu'
      hidden={!visible}
      aria-orientation='vertical'
      labelled='menu-button'
      tabIndex='-1'
    >
      <div className='py-1' role='none'>
        {_dataArr.map((data, index) => {
          let translatedData = data;
          if (props.hasOwnProperty("t")) {
            translatedData = props.t(`${props.tPrefix}${data.toString().toLowerCase()}`);
          }
          return (
            <button
              key={index}
              onClick={() => handleClick(data)}
              className='text-gray-700 w-full block px-4 py-2 text-sm text-center hover:bg-gray-100 hover:text-gray-900'
              role='menuitem'
              tabIndex='-1'
              id={`menu-item-${index}`}
            >
              <p className='text-xxs sm:text-xs md:text-l lg:text-xl '>{translatedData}</p>
            </button>
          );
        })}
      </div>
    </div>
  );
};

export default DropDown;
