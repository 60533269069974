/// Box in calendar component

// Path: calendar-app/src/Component/Calendar/Box.js
import React, { useLayoutEffect, useState } from "react";
import "./css/Box.css";

const Box = ({ holidayData, numbersOfDays, firstDayOfEachMonth, currentYear, currentMonthIndex, ...props }) => {
  const [holidayInfo, setHolidayInfo] = useState([]);
  useLayoutEffect(() => {
    handleHolidayData();
  }, [holidayData, currentMonthIndex, currentYear]);
  const handleHolidayData = () => {
    let _holidayInfo = [];
    if(!holidayData) return;
    holidayData.forEach((holiday) => {
      const holidayYear = new Date(holiday.start.date).getFullYear();
      const holidayMonth = new Date(holiday.start.date).getMonth();
      const holidayDay = new Date(holiday.start.date).getDate();
      if (holidayYear === currentYear && holidayMonth === currentMonthIndex) {
        if (holiday.description == "Public holiday") _holidayInfo[`"${holidayDay}"`] = { holidayName: holiday.summary, isPublicHoliday: true };
        else _holidayInfo[`"${holidayDay}"`] = { holidayName: holiday.summary, isPublicHoliday: false };
      }
    });

    setHolidayInfo(_holidayInfo);
  };


  return (
    <div className='grid grid-cols-7  text-xxs sm:text-xs md:text-l lg:text-xl  '>
      {Array.from({ length: numbersOfDays + firstDayOfEachMonth[currentMonthIndex] + 1 }, (_, index) => {
        if (index < firstDayOfEachMonth[currentMonthIndex] - 1) {
          return <div key={index}></div>; // Skip rendering the element
        }
        if (index > numbersOfDays + firstDayOfEachMonth[currentMonthIndex] - 2) return <div key={index}></div>;

        let holiday = holidayInfo[`"${index - firstDayOfEachMonth[currentMonthIndex] + 2}"`];
        let holidayName = holiday?.holidayName? holiday?.holidayName : "";
        if(props.hasOwnProperty('t') ){
          let translatedData = props.t(`${props.tPrefix}${holiday?.holidayName?.toString().toLowerCase()}`);
      
          if(!translatedData.includes('holiday.')){
            holidayName = translatedData;
          }
         
        }
        return (
          <div key={index}  >
            <div
              className={`border  ${
                index % 7 == 0 || holiday?.isPublicHoliday ? "text-customColor-secondary" : ""
              }  border-gray-100 min-height-calendar pl-4`}
              key={index}
            >
              <span>{index - firstDayOfEachMonth[currentMonthIndex] + 2}</span>
              <br />
              <span className={`${holiday?.isPublicHoliday?'text-customColor-secondary' : ''}  font-['ShadowsIntoLight'] font-medium text-xxs sm:text-xs md:text-l lg:text-xl `}>
              <p className=" md:scale-y-95 md:scale-x-95"> {holidayName}</p>
              </span>
            
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default Box;
