import React, { useEffect, useMemo, useState, useCallback } from "react";
import "./css/Calendar.css";
import DropDown from "../DropDown/DropDown";
import Loading from "../Loading/Loading";
import ArrowDownIcon from "./ArrowDownIcon";
import { COUNTRY, MONTHS, WEEKDAYS, YEARS, googleCalendarIds } from "./data/constants.js";
import Box from "./Box";
import { startConnection, closeConnection, getKey, createHolidayIndex } from "../../util/redis/connector";
import { useTranslation } from "react-i18next";

const Calendar = () => {
  const weekdays = useMemo(() => WEEKDAYS, []);
  const years = useMemo(() => YEARS(), []);
  const months = useMemo(() => MONTHS, []);
  const countries = useMemo(
    () => COUNTRY.filter((val) => Object.keys(googleCalendarIds).some((v) => v === val.name)),
    []
  );
  const { t, i18n } = useTranslation();
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
  const [currentRegion, setCurrentRegion] = useState("Hong Kong"); // Hong Kong
  const [firstDayOfEachMonth, setFirstDayOfEachMonth] = useState([]);
  const [currentMonth, setCurrentMonth] = useState(MONTHS[new Date().getMonth()]);
  const [monthDropdownVisible, setMonthDropdownVisible] = useState(false);
  const [yearDropdownVisible, setYearDropdownVisible] = useState(false);
  const [regionDropdownVisible, setRegionDropdownVisible] = useState(false);
  const [currentMonthIndex, setCurrentMonthIndex] = useState(new Date().getMonth());
  const [holidayData, setHolidayData] = useState([]);
  const [loading, setLoading] = useState(false);
  let redisClient = null;
  const batchSize = 60;
  useEffect(() => {
    async function connect() {
      i18n.changeLanguage("zh");
      let client = await startConnection();
      redisClient = client;
    }
    connect();
    return () => {
      closeConnection();
    };
  }, []);

  useEffect(() => {
    const firstDays = [];

    for (let month = 0; month < 12; month++) {
      const firstDay = new Date(currentYear, month, 1);
      const firstDayIndex = firstDay.getDay();
      firstDays.push(firstDayIndex + 1);
    }
    setFirstDayOfEachMonth(firstDays);
  }, [currentYear]);

  useEffect(() => {
    setCurrentMonthIndex(months.indexOf(currentMonth));
  }, [currentMonth, months]);

  const numbersOfDays = useMemo(() => {
    return new Date(new Date().getFullYear(), currentMonthIndex + 1, 0).getDate();
  }, [currentMonthIndex]);

  const getHolidayFromGoogleCalendar = useCallback(async () => {
    setLoading(true);
    let data = [];
    let region = googleCalendarIds[currentRegion];

    if (!redisClient) {
      let client = await startConnection();
      redisClient = client;
    }
    let checkExist = await redisClient.exists(region);

    if (checkExist == 1) {
      data = await redisClient.smembers(region);
      setHolidayData(data);
      setLoading(false);
      return;
    }

    const response = await fetch(
      `https\://www.googleapis.com/calendar/v3/calendars/en.${region}%23holiday%40group.v.calendar.google.com/events?key=${process.env.REACT_APP_GOOGLE_CALENDAR_API_KEY}`
    );
    data = await response.json();
    if (data && data.items) {
      setHolidayData(data.items);

      for (let i = 0; i < data.items.length; i += batchSize) {
        let batch = data.items.slice(i, i + batchSize);
        await redisClient.sadd(region, ...batch);
      }
      /* 
        console.log("Key exist.");
        for (let i = 0; i < data.items.length; i += batchSize) {
          let batch = data.items.slice(i, i + batchSize);
         await redisClient.json.arrinsert(region,'$',0,batch);
        await redisClient.hset(region,...batch);
        }
      } else {
        await redisClient.hset(region,data.items);
       await redisClient.json.set(region,"$",[data.items[0]] );
        await redisClient.expire(region, 360*24*60*60);
      }*/
      await redisClient.expire(region, 360 * 24 * 60 * 60);
    }
    setLoading(false);

    console.log("loading", loading);
  }, [currentRegion]);

  useEffect(() => {
    getHolidayFromGoogleCalendar();
  }, [currentRegion, getHolidayFromGoogleCalendar]);

  const handleYearDropdown = (e) => {
    e.stopPropagation();
    hiddenAllDropdown("year");
    setYearDropdownVisible(!yearDropdownVisible);
  };

  const handleMonthDropdown = (e) => {
    e.stopPropagation();
    hiddenAllDropdown("month");
    setMonthDropdownVisible(!monthDropdownVisible);
  };

  const handleRegionDropdown = (e) => {
    e.stopPropagation();
    hiddenAllDropdown("region");
    setRegionDropdownVisible(!regionDropdownVisible);
  };

  const hiddenAllDropdown = (except = "no") => {
    switch (except) {
      case "year":
        setMonthDropdownVisible(false);
        setRegionDropdownVisible(false);
        break;
      case "month":
        setYearDropdownVisible(false);
        setRegionDropdownVisible(false);
        break;
      case "region":
        setYearDropdownVisible(false);
        setMonthDropdownVisible(false);
        break;
      default:
        setYearDropdownVisible(false);
        setMonthDropdownVisible(false);
        setRegionDropdownVisible(false);
        break;
    }
  };
  return (
    <div className=' calendar ' onClick={() => hiddenAllDropdown()}>
      <Loading loading={loading} />
      <h1
        className=' sm:text-l md:text-xl lg:text-2xl font-bold mb-4 text-customColor-darkText flex justify-center
       '
      >
        <span className=' flex ' onClick={handleRegionDropdown}>
          <span className='flex flex-auto cursor-pointer '>
            <p className='flex-auto '> {t(`country.${currentRegion.toString().toLowerCase()}`)} </p>
            <ArrowDownIcon className={"flex-auto"} />
          </span>
          <p className='flex-auto ml-2 '> {t("calendar")} </p>{" "}
          <DropDown
            className={"mt-4"}
            dataArr={countries.map((country) => country.name)}
            visible={regionDropdownVisible}
            setVisible={setRegionDropdownVisible}
            setCurrentData={setCurrentRegion}
            t={t}
            tPrefix={"country."}
          />
        </span>
      </h1>
      <div className='bg-white  sm:px-2  mx-auto  md:px-10 py-10 rounded-4xl shadow-4xl'>
        <h1 className='sm:text-md md:text-xl lg:text-2xl  mb-6 flex justify-start  '>
          <span className='flex ml-2'>
            <span className='flex-auto flex cursor-pointer' onClick={handleYearDropdown}>
              <p className='flex-auto '>{t(currentYear.toString().toLowerCase())} </p>
              <ArrowDownIcon />
            </span>
            <span className='flex-auto flex cursor-pointer' onClick={handleMonthDropdown}>
              <span className='flex-auto  ml-4'>{t(`month.${currentMonth.toLowerCase()}`)}</span>
              <ArrowDownIcon />
            </span>
          </span>
        </h1>

        <DropDown
          className={"-mt-2"}
          dataArr={years}
          visible={yearDropdownVisible}
          setVisible={setYearDropdownVisible}
          setCurrentData={setCurrentYear}
        />
        <DropDown
          dataArr={months}
          className={"ml-24 -mt-4"}
          visible={monthDropdownVisible}
          setVisible={setMonthDropdownVisible}
          setCurrentData={setCurrentMonth}
          t={t}
          tPrefix={"month."}
        />
        <div className=''>
          {/*Loop days array*/}
          <div className='flex ml-3 justify-evenly text-xxs sm:text-xs md:text-l lg:text-xl text-center mb-3'>
            {weekdays.map((day, index) => (
              <h1 key={index} className='flex-auto '>
                {t(`weekday.${day.toLowerCase()}`)}
              </h1>
            ))}
          </div>
          <Box
            currentYear={currentYear}
            holidayData={holidayData}
            numbersOfDays={numbersOfDays}
            firstDayOfEachMonth={firstDayOfEachMonth}
            currentMonthIndex={currentMonthIndex}
            t={t}
            tPrefix={"holiday."}
          />
        </div>
      </div>
    </div>
  );
};

export default Calendar;
